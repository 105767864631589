<template>
  <section class="container">
    <main class="main-download">
      <div class="info-main">
        <div class="dados-produto">
          <img :src="product.img" alt="" />
          <div class="dados-produto-sub">
            <h5>{{ product.name }}</h5>
            <h6 class="author-title">Autor:  <span class="author-name">{{ product.author }}</span></h6>
          </div>
        </div>

        <h1 data-anima="top">
          Obrigado por <br> comprar o produto <br>
          <h2>{{ product.name }}</h2>
        </h1>
        <h4 data-anima="bottom">
          Abaixo estão todos os <span> arquivos para download</span><br> disponibilizados pelo produtor
        </h4>

        <div class="listagem-arquivos" data-anima="bottom">
          <div class="lista">
            <ul v-if="!loading && listagem_files.length">
              <li
                v-for="file in listagem_files"
                :key="file.id"
                :id="'file-' + file.id"
                class="file-item"
                @click="downloadFile(file.id, file.name)"
              >
                <div class="ilu">
                  <span>
                    {{ file.name }}
                  </span>
                </div>
                <img src="@/assets/img/icons/down.svg" />
                <b-tooltip
                  :target="'file-' + file.id"
                  title="Clique para baixar o arquivo"
                />
              </li>
            </ul>

            <div
              key="loading"
              v-if="loading"
              class="py-5 my-5 d-flex justify-content-center align-items-center"
            >
              <b-spinner variant="dark" label="Spinning"> </b-spinner>
            </div>

            <b-row
              v-if="!loading && !listagem_files.length"
              class="Table-body justify-content-center"
            >
              <p class="nao-encontrado">Nenhum arquivo disponível</p>
            </b-row>
          </div>
        </div>
      </div>

      <div class="ilustracao" data-anima="bottom">
        <img src="@/assets/img/ilu-download.png" />
      </div>
    </main>

    <footer class="footer-download">

      <div>
          <a
            :href="helpLink"
            target="_blank"
            class="help"
            data-anima="right"
          >
            <div class="icon-help">
              <img src="@/assets/img/icons/new-help.svg" />
            </div>
            <span>Preciso de ajuda</span>
          </a>

      </div>

      <div class="div-logo">
        <span>Feito com</span>
        <a :href="voompLink" target="_blank">
          <img src="@/assets/img/icons/logo-voomp.svg" />
        </a>
      </div>
    </footer>
  </section>
</template>

<script>
import FileService from "@/services/resources/FileService";
const serviceFiles = FileService.build();

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

export default {
  name: "DownloadFiles",
  data() {
    return {
      loading: false,
      product: {
        name: "",
        img: "",
        author: ""
      },
      listagem_files: [],
      product_id: null,
      sale_id: null,
      hash: null,
      helpLink: process.env.VUE_HELP_PAGE,
      voompLink: process.env.VUE_VOOMP_PAGE
    };
  },
  methods: {
    downloadFile(id_file, name) {
      this.loading = true;
      let data = {
        url: id_file,
        sale_id: this.sale_id,
        product_id: this.product_id,
        hash: this.hash,
      };

      serviceFiles
        .blobSearch(data)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFiles() {
      this.loading = true;
      const data = { id: `${this.product_id}/attachments`};

      serviceProduct
        .read(data)
        .then((response) => {
          const { product, seller } = response;

          this.product = {
            name: product.name,
            img: product.image_url,
            author: seller.name
          };

          this.listagem_files = product.attachments || [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    const params = this.$route.params;
    if (typeof params.product_id != "undefined") {
      this.product_id = params.product_id;
    }
    if (typeof params.sale_id != "undefined") {
      this.sale_id = params.sale_id;
    }

    if (typeof params.hash != "undefined") {
      this.hash = params.hash;
    }
    this.getFiles();
  },
};
</script>

<style scoped>
@media screen and (max-width: 667px) {
  .container {
    padding: 10px 20px !important;
  }
}
/* header */
.header-download {
  padding: 40px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-download {
  font-weight: 600;
  font-size: 14px;
}

.file-item{
  word-break: break-all;
}
@media screen and (max-width: 667px) {
  .header-download {
    padding: 25px 0;
  }
  .logo-download {
    font-size: 12px;
  }
}

.help {
  display: flex;
  align-items: center;
  gap: 15px;
}
.icon-help {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #BFBFBF;
}
.help span {
  color: #091E3E;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
@media screen and (max-width: 667px) {
  .help {
    gap: 0;
  }
  .icon-help {
    height: 30px;
    width: 30px;
  }
  .icon-help img {
    width: 24px;
  }
  .help span {
    font-size: 12px;
  }
  .div-logo{
    width: 145px !important;
  }
  .div-logo span{
    width: 68px;
  }
}

/*  main*/
.main-download {
  display: grid;
  grid-template-columns: 600px 1fr;
  margin-top: 80px;
}

@media screen and (max-width: 667px) {
  .main-download {
    margin-top: 20px;
    grid-template-columns: 1fr;
  }
}
.dados-produto {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  margin-bottom: 100px;
}
.dados-produto img {
  max-width: 72px;
  height: 72px;
  border-radius: 50%;
}
.dados-produto h5 {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  line-height: 27px;
  color: #091E3E;
}

.info-main h1,
.info-main h2 {
  color: #091E3E;
  font-size: 48px;
  font-weight: 600;
  line-height: 62.4px;
}
.info-main h1{
  margin-bottom: 25px;
}

.info-main h4 {
  color: #091E3E;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.info-main h4 span {
  font-weight: 600;
  font-size: 18px;
}
.info-main h1 h2 {
  color: #FF8200;
  display: inline;
}
.info-main h6{
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  line-height: 18px;
  color: #091E3E;
}
.info-main p {
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  line-height: 18px;
  color: #091E3E;
}
@media screen and (max-width: 667px) {
  .info-main h1,
  .info-main h2 {
    font-size: 31px;
    line-height: 41.6px;
  }
  .info-main p {
    margin: 10px 0 20px 0;
    font-size: 15px;
  }
  .dados-produto {
    margin: 15px 0;
  }
  .ilustracao{
    top:40px !important;
  }
}
.info-main span {
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  line-height: 18px;
  color: #091E3E;
}
.info-main .btn-download {
  width: 365px;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.info-main .btn-download img {
  filter: invert(50%) brightness(5);
}
.info-main .btn-download:disabled img {
  filter: invert(50%) brightness(1);
}
@media screen and (max-width: 667px) {
  .info-main .btn-download {
    width: auto;
    padding: 0 40px !important;
  }
}
/* lista */
.listagem-arquivos {
  display: grid;
  grid-template-columns: 500px 1fr;
}
.listagem-arquivos .arrow-down {
  filter: invert(1) brightness(0);
  animation: animaArrow 1.5s infinite alternate;
}
@keyframes animaArrow {
  0%,
  40% {
    transform: translate3d(0, 0, 0);
  }
  70% {
    transform: translateY(7px);
  }
}

@media screen and (max-width: 667px) {
  .listagem-arquivos {
    grid-template-columns: 1fr;
    margin-top: 0px;
  }
}

.titulo-lista {
  display: flex;
  align-items: center;
}
.titulo-lista h3 {
  font-weight: 800;
  font-size: 20px;
  margin-left: 40px;
}
@media screen and (max-width: 667px) {
  .titulo-lista h3 {
    font-size: 18px;
  }
}

/* lista */
.lista ul {
  margin: 40px 0;
}
/* itemfile */
.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 2px 4px 10px rgba(165, 164, 172, 0.1);
  border: 1px solid #E6E9EC;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}
.file-item:hover {
  transform: scale(1.07);
}
.file-item + .file-item {
  margin-top: 20px;
}
.file-item .ilu {
  display: flex;
  align-items: center;
}
.file-item .ilu span {
  margin-left: 5px;
  margin-right:10px;
  font-weight: 600;
  font-size: 14px;
  color: #091E3E;
  max-width: 100%;
  line-height: 21px;
  word-break: break-all;
}
@media screen and (max-width: 667px) {
  .lista ul {
    margin-bottom: 0;
  }
  .file-item {
    padding: 20px 30px;
  }
  .file-item:hover {
    transform: none;
  }
  .file-item .ilu span {
    font-size: 14px;
  }
}
.div-logo{
  display: flex;
  border: 1px solid #E6E9EC;
  padding: 10px;
  border-radius: 8px;
  gap: 10px;
  height: 41px;
  width: 170px;
  justify-content: center;
  position: relative;
  bottom: 10px;
}

.div-logo span{
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  color: #CBCBCB;
}
/* footer */
.footer-download {
  position: relative;
  bottom: 0;
  margin-top: 120px;
  padding: 40px 0 38px 10px;
  font-size: 15px;
  color: #81858e;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.footer-download a {
  color: #020016;
  font-weight: 600;
}
@media screen and (max-width: 667px) {
  .footer-download {
    margin-top: 80px;
    padding: 30px 0;
    padding-left: 10px;
  }
}

/* ilustra fixed */
.ilustracao {
  position: relative;
  top: 140px;
  left: 100px;
}

@media screen and (max-width: 667px) {
  .ilustracao {
    position: relative;
    z-index: -1;
    bottom: 9px;
    right: 0;
    left:0;
  }
}
</style>
